import React, { useReducer } from 'react'
import BuynowContext from './buynowContext'
import BuynowReducer from './buynowReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_SEARCH,
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
    GET_SINGLE_CART,
    GET_ALL_SEARCH_TEMP,
    GET_SINGLE_CART_TEMP,
    CLEAR_SINGLE_CART,
} from './buynowTypes'

const BuynowState = (props) => {
    const initialState = {
        search_allproducts: {
            cartrecords: [],
            noncartrecords: [],
        },
        search_tempProducts: {
            cartrecords: [],
            noncartrecords: [],
        },
        search_singlecart: {
            cartrecords: [],
            cartvalues: {},
            cartLocation: {},
            from: '',
        },
        search_singlecart_temp: {
            cartrecords: [],
            cartvalues: {},
            cartLocation: {},
            from: '',
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(BuynowReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const addToCart = async (formData, fromVariable) => {
        try {
            const from = fromVariable ? fromVariable : 'addToCart'
            const [res] = await Promise.all([apiCall('post', 'addToCart', formData, '', 'buynow')])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse('addToCart')
        }
    }

    const addToBuynow = async (formData, fromVariable) => {
        try {
            const from = fromVariable ? fromVariable : 'addToBuynow'
            const [res] = await Promise.all([
                apiCall('post', 'addToBuynow', formData, '', 'buynow'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse('addToBuynow')
        }
    }

    const updateAdminFee = async (formData, fromVariable) => {
        try {
            const from = fromVariable ? fromVariable : 'updateAdminFee'
            const [res] = await Promise.all([
                apiCall('post', 'updateAdminFee', formData, '', 'buynow'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse('updateAdminFee')
        }
    }

    const addToCartTemp = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addToCartTemp', formData, '', 'buynowTemp'),
            ])
            resp.commonResponse(res.data, 'addToCartTemp')
        } catch (err) {
            resp.commonErrorResponse('addToCartTemp')
        }
    }

    const updateCertificate = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateOwnershipCertificate', formData, '', 'buynow'),
            ])
            resp.commonResponse(res.data, 'updateCertificate')
        } catch (err) {
            resp.commonErrorResponse('updateCertificate')
        }
    }

    const addToMakeOffer = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addToMakeoffer', formData, '', 'makeoffer'),
            ])
            resp.commonResponse(res.data, 'addToMakeoffer')
        } catch (err) {
            resp.commonErrorResponse('addToCart')
        }
    }

    const refreshCart = async (formData, noAlert, from) => {
        from = from ? from : 'refreshCart'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'refreshCart', formData, '', 'buynow'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const getSingleCartProducts = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'checkout', formData, '', 'buynow')])
            const from = fromVariable ? fromVariable : 'cartsearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_CART,
                    payload: {
                        cartrecords: res.data.data.responseData.cartItems.length
                            ? res.data.data.responseData.cartItems
                            : [],
                        cartvalues: res.data.data.responseData.cartValues,
                        cartLocation: res.data.data.responseData.cartLocation,
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    const getSingleCartTempProducts = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkouttemp', formData, '', 'buynowtemp'),
            ])
            const from = fromVariable ? fromVariable : 'cartsearchTemp'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_CART_TEMP,
                    payload: {
                        cartrecords: res.data.data.responseData.cartItems.length
                            ? res.data.data.responseData.cartItems
                            : [],
                        cartvalues: res.data.data.responseData.cartValues,
                        cartLocation: res.data.data.responseData.cartLocation,
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearchTemp')
        }
    }

    const getAllCartTempProducts = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'searchTemp', formData, '', 'buynowtemp'),
            ])
            const from = 'cartsearch_temp'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SEARCH_TEMP,
                    payload: {
                        cartrecords: res.data.data.responseData.cartItems.length
                            ? res.data.data.responseData.cartItems
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                        type: res.data.data.responseType,
                        from: from,
                    },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            console.log('Something went wrong!', err)
            resp.commonErrorResponse('cartsearch_temp')
        }
    }

    const getAllCartProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'search', formData, '', 'buynow')])
            const from = 'cartsearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SEARCH,
                    payload: {
                        cartrecords: res.data.data.responseData.cartItems.length
                            ? res.data.data.responseData.cartItems
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                        type: res.data.data.responseType,
                        from: from,
                    },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            console.log('Something went wrong!', err)
            resp.commonErrorResponse('cartsearch')
        }
    }
    const getMakeofferAllCartProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'search', formData, '', 'makeoffer')])
            const from = 'makeoffer'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SEARCH,
                    payload: {
                        cartrecords: res.data.data.responseData.cartItems.length
                            ? res.data.data.responseData.cartItems
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                        type: res.data.data.responseType,
                        from: from,
                    },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            console.log('Something went wrong!', err)
            resp.commonErrorResponse('makeoffer')
        }
    }

    // Register User
    const changeOnCart = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'changeStatus', formData, '', 'buynow'),
            ])
            resp.commonResponse(res.data, 'cartchange')
        } catch (err) {
            resp.commonErrorResponse('cartchange')
        }
    }

    const changeOnCartTemp = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'changeStatusTemp', formData, '', 'buynowtemp'),
            ])
            resp.commonResponse(res.data, 'cartchangeTemp')
        } catch (err) {
            resp.commonErrorResponse('cartchangeTemp')
        }
    }

    const cancelItem = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'cancelItem', formData, '', 'cart')])
            resp.commonResponse(res.data, 'cartchange')
        } catch (err) {
            resp.commonErrorResponse('cartchange')
        }
    }

    const saveAddress = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'saveAddress', formData, '', 'cart')])
            resp.commonResponse(res.data, 'cartaddress')
        } catch (err) {
            resp.commonErrorResponse('cartaddress')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    const clearSingleCart = () =>
        dispatch({
            type: CLEAR_SINGLE_CART,
        })

    return (
        <BuynowContext.Provider
            value={{
                search_allproducts: state.search_allproducts,
                search_singlecart: state.search_singlecart,
                search_tempProducts: state.search_tempProducts,
                search_singlecart_temp: state.search_singlecart_temp,
                responseStatus: state.responseStatus,
                addToCart,
                addToBuynow,
                updateAdminFee,
                updateCertificate,
                addToMakeOffer,
                refreshCart,
                addToCartTemp,
                getSingleCartProducts,
                clearResponse,
                saveAddress,
                getAllCartTempProducts,
                getAllCartProducts,
                getSingleCartTempProducts,
                getMakeofferAllCartProducts,
                changeOnCart,
                changeOnCartTemp,
                cancelItem,
                clearSingleCart,
            }}
        >
            {props.children}
        </BuynowContext.Provider>
    )
}

export default BuynowState
